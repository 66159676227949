import React from "react";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { BeatLoader } from "react-spinners";
import { useLocation } from "react-router-dom";

export default function PricingUpdateSuccess() {
  const { auth, refreshUserData } = useAuth();
  const [subscriptionDeliveryCheck, setSubscriptionDeliveryCheck] = useState(true);
  const [userState, setUserState] = useState(auth.user);
  const { state } = useLocation();

  useEffect(() => {
    setUserState(auth.user);
  }, [auth.user]);

  useEffect(() => {
    let timeoutId;

    const refreshState = () => {
      const tier = state.tier;
      const frequency = state.frequency;

      if (state.new_tier === "topup") {
        // check if topup credits were added
        if (auth.user.podscribe_topup_credits !== state.new_topup_credits) {
          refreshUserData();
          console.log("Still validating new topup credits...");
          timeoutId = setTimeout(refreshState, 2000); // Adjust the delay as needed
          console.log("Timeout ID: ", timeoutId);
        } else {
          console.log("Topup credits added");
          clearTimeout(timeoutId);
          setSubscriptionDeliveryCheck(false);
        }
      } else {
        // check if subscription was updated
        if (
          auth.user.subscription_tier === state.new_tier &&
          auth.user.subscription_frequency === state.new_frequency
        ) {
          refreshUserData();
          console.log("Still processing...");
          timeoutId = setTimeout(refreshState, 2000); // Adjust the delay as needed
          console.log("Timeout ID: ", timeoutId);
        } else {
          console.log("Subscription updated");
          clearTimeout(timeoutId);
          setSubscriptionDeliveryCheck(false);
        }
      }
    };
    refreshState();

    // Clear the timeout when the component unmounts, the dependencies change, or the episode is no longer selected
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        console.log("Clearing timeout: ", timeoutId);
      }
    };
  }, [auth.user]);

  return (
    <>
      <div className=" bg-white px-4 py-5 border-b border-neutral-200 sm:px-6 m-3">
        {/* Page Header */}
        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h3 className="text-lg leading-6 font-medium text-primary-600">Subscription</h3>
            <p className="text-sm leading-6 text-neutral-500">Update to your subscription</p>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <CheckCircleIcon className="h-24 w-24 text-primary-600 mx-auto" />
            <p className="mt-2 text-4xl font-bold tracking-tight text-neutral-900 sm:text-5xl">
              Your subscription was updated!
            </p>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-neutral-600">
            Your update has been successfully processed. Please allow a few minutes for the changes to take effect, to
            access all our features.
          </p>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-neutral-600">
            If you have any questions, please contact us at{" "}
            <a href="mailto:info@podscribe.io" className="text-primary-600">
              info@podscribe.io
            </a>
            .
          </p>
        </div>
        {/* Loading spinner */}
        {subscriptionDeliveryCheck ? (
          <div className="flex justify-center items-center">
            <BeatLoader color={"#fca311"} loading={subscriptionDeliveryCheck} size={15} />
          </div>
        ) : (
          <div className="flex justify-center items-center">
            <p className="text-center text-lg text-support-green-600">Subscription update configured successfully!</p>
          </div>
        )}
      </div>
    </>
  );
}
