import react, { useEffect, useState, useRef } from "react";
import { Fragment } from "react";
import CardHeadingChildButtons from "../UI/CardHeadingChildButtons";
import { SearchIcon, XIcon, PlusIcon } from "@heroicons/react/outline";
import { Link, useParams } from "react-router-dom";
import Button from "../UI/Button";
import PodcastCard from "../UI/PodcastCard";
import { axiosPrivate } from "../common/axiosPrivate";
import { BarLoader } from "react-spinners";
import { useReducer } from "react";
import SearchInput from "../UI/SearchComponent";
import { useDebounceValue } from "usehooks-ts";
import SEO from "./SEO";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

//reducer

const FETCH_DATA = "FETCH_DATA";
const FILTER_FEEDS = "FILTER_FEEDS";
const SHOW_MORE = "SHOW_MORE";
const SORT_FAVORITES = "SORT_FAVORITES";

const initialState = {
  feeds: [],
  filteredFeeds: [],
  displayCount: 5, // New state for tracking display count
};

const feedsReducer = (state, action) => {
  switch (action.type) {
    case FETCH_DATA:
      return {
        ...state,
        feeds: action.payload,
      };
    case FILTER_FEEDS:
      if (action.payload) {
        return {
          ...state,
          filteredFeeds: state.feeds
            .filter((feed) => feed.title.toLowerCase().includes(action.payload.toLowerCase()))
            .sort((a, b) => b.is_favorite - a.is_favorite),
        };
      }
      return {
        ...state,
        filteredFeeds: [...state.feeds].sort((a, b) => b.is_favorite - a.is_favorite).slice(0, state.displayCount),
      };
    case "FAVORITE":
      const feedId = action.payload.feedId;
      const isFavorite = action.payload.isFavorite;
      const feeds = state.feeds.map((feed) => {
        if (feed.id === feedId) {
          console.log("Feed id: ", feed.id, "modified feed: ", feed);
          return { ...feed, is_favorite: isFavorite };
        }
        return feed;
      });
      return {
        ...state,
        feeds,
      };
    case SHOW_MORE:
      return {
        ...state,
        displayCount: state.displayCount + 5,
      };
    default:
      return state;
  }
};

// Module scoped variables
// Module scoped variables

export default function Favorites(props) {
  const { searchTerm } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [state, dispatch] = useReducer(feedsReducer, initialState);
  const [filterTerm, setFilterTerm] = useState("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useDebounceValue("", 300);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axiosPrivate.get(`/podcast/favorites`);
        dispatch({ type: FETCH_DATA, payload: response.data.podcasts });
        dispatch({ type: FILTER_FEEDS });
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching favorites: ", error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleFavoriteOnClick = async (feedId, isFavorite) => {
    try {
      await axiosPrivate.post(`/podcast/favorites/${feedId}`, {
        is_favorite: isFavorite,
      });
    } catch (error) {
      console.error("Error updating favorite status: ", error);
    }
    setFilterTerm("");
    dispatch({ type: "FAVORITE", payload: { feedId, isFavorite } });
    dispatch({ type: FILTER_FEEDS }); //log the favorite feeds
  };

  useEffect(() => {}, [state.feeds]);
  const handleShowMore = (state, dispatch) => {
    dispatch({ type: SHOW_MORE });
    dispatch({ type: FILTER_FEEDS });
  };

  const handleSearch = (e) => {
    setDebouncedSearchValue(e.target.value);
    setFilterTerm(e.target.value);
  };

  useEffect(() => {
    dispatch({ type: FILTER_FEEDS, payload: debouncedSearchValue });
  }, [debouncedSearchValue]);

  return (
    <>
      <SEO title="PodScribe.IO | Favorites" />
      <div className="flex">
        <section
          aria-labelledby="message-heading"
          className="min-w-0 flex-1 lg:h-screen flex flex-col overflow-y-auto lg:order-last">
          {/* Main Content. Note the lg:h-screen is important because of the Navigation menu */}
          <BarLoader width={"100%"} color={"#fca311"} loading={isLoading} size={150} />
          <CardHeadingChildButtons
            title="Favorites"
            subHeading="Podcast that you have added to your inbox, liked or bookmarked.">
            <SearchInput
              value={filterTerm}
              onChange={(e) => {
                handleSearch(e);
              }}
              className=""
            />
          </CardHeadingChildButtons>
          <div className="flex flex-col flex-1 lg:overflow-y-auto items-center">
            <div className="h-screen h-screen-ios w-screen md:w-8/12 lg:w-9/12 px-2 py-2">
              <h2 className="text-xl text-center text-neutral-700">My Favorite Podcasts</h2>
              {/* Search results go here if there are*/}

              {!isLoading && state.feeds.length === 0 && (
                <div className="text-center mt-4">
                  <h3 className="text-lg text-neutral-400">
                    You have no favorite podcasts yet. Click on the search button to find podcasts to favorite.
                  </h3>
                </div>
              )}
              <div className="mt-4 mx-3">
                {!isLoading &&
                  state.filteredFeeds.map((feed, index) => (
                    <PodcastCard key={index} feed={feed} handleFavorite={handleFavoriteOnClick} />
                  ))}
              </div>
              <div className="mt-4 mx-3 pb-12">
                {!filterTerm && !isLoading && state.displayCount < state.feeds.length && (
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center">
                      <Button type="button" onClick={() => handleShowMore(state, dispatch)} className="py-1 gap-x-1">
                        <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        Load More
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
