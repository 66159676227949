// Library that will fetch the users Library from the backend and display it.
// top level component to show the podcasts, the episodes and the player

import React, { useState, useEffect, useReducer } from "react";
import useFetch from "../hooks/useFetch";
import useAuth from "../hooks/useAuth";
import useLibraryData from "../hooks/useLibraryData";
import { FadeLoader } from "react-spinners";
import toast from "react-hot-toast";
import { Navigate, Outlet, Route, useOutletContext } from "react-router-dom";
import Podcasts from "./Podcasts";
import Inbox from "./Library/Inbox";
import InboxMessage from "./Library/InboxMessage";
import InboxContent from "./Library/InboxContent";
import CardHeadingAction from "../UI/CardHeading";
import axios from "axios";
import InboxSidebar from "./Library/MobileInbox";
import { axiosPrivate } from "../common/axiosPrivate";
import { useNavigate } from "react-router-dom";
import Modal from "../UI/Modal";
import Search from "./Library/Search";
import SEO from "./SEO";

const initialStateSelectedEpisodeReducer = {
  episode: null, // the episode object
  episode_id: null, // the episode id
  is_transcribed: false,
  is_summarized: false,
  is_processing: false,
  transcription_data: null, // the transFcription data
  note_data: null, // the note data object
  save_note_data: null, // the note data object
};

const selectedEpisodeReducer = (state, action) => {
  switch (action.type) {
    case "INIT":
      return {
        ...state,
        episode: action.episode,
        episode_id: action.episode.id,
        is_transcribed: action.episode.is_transcribed,
        is_summarized: action.episode.is_summarized,
        is_processing: action.episode.is_processing,
        state: action.episode.state,
        transcription_data: action.episode.transcription,
      };
    case "SET_EPISODE":
      return {
        ...state,
        episode: action.episode,

        episode_id: action.episode.id,
        is_transcribed: action.episode.is_transcribed,
        is_summarized: action.episode.is_summarized,
        is_processing: action.episode.is_processing,
        state: action.episode.state,
        transcription_data: action.transcription_data,
      };
    case "DE_SELECT_EPISODE":
      return {
        ...state,
        episode: null,
        episode_id: null,
        is_transcribed: false,
        transcription_data: null,
        note_data: null,
        save_note_data: null,
      };
  }
};

// ****** Library Component ******

export default function Library(props) {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [FetchedData, setFetchedData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedEpisodeState, dispatchSelectedEpisodeState] = useReducer(
    selectedEpisodeReducer,
    initialStateSelectedEpisodeReducer
  );
  const { LibraryData, setLibraryData, refreshLibraryEpisode, removeLibraryEpisode } = useLibraryData();
  const [selectedEpisodeEditorData, setSelectedEpisodeEditorData] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  // state related to open the Search Modal
  const [openSearchModal, setOpenSearchModal] = useState(false);

  const handleOpenSearchModal = () => {
    setOpenSearchModal(true);
  };

  // Data is fetched and accessed from LibraryData context

  // fix for SimpleMDE
  window.addEventListener(
    "scroll",
    (event) => {
      // Your scroll event handler code here
    },
    { passive: true }
  );

  // Handle SelectNextEpisode
  const SelectNextEpisodeHandler = () => {
    // get the index of the current episode
    const index = LibraryData.episodes.findIndex((episode) => episode.id === selectedEpisodeState.episode_id);
    // get the next episode
    if (index === LibraryData.episodes.length - 1) {
      // if the current episode is the last one do nothing
      console.log("This is the last episode");
      return;
    } else {
      const nextEpisode = LibraryData.episodes[index + 1];
      // set the selected episode
      dispatchSelectedEpisodeState({
        type: "SET_EPISODE",
        episode: nextEpisode,
      });
    }
  };

  // Handle SelectPreviousEpisode
  const SelectPreviousEpisodeHandler = () => {
    // get the index of the current episode
    const index = LibraryData.episodes.findIndex((episode) => episode.id === selectedEpisodeState.episode_id);
    // get the previous episode
    if (index === 0) {
      // if the current episode is the first one do nothing
      console.log("This is the first episode");
      return;
    } else {
      const previousEpisode = LibraryData.episodes[index - 1];
      // set the selected episode
      dispatchSelectedEpisodeState({
        type: "SET_EPISODE",
        episode: previousEpisode,
      });
    }
  };

  // Handle selected Episode
  const SelectEpisodeHandler = (id) => {
    // get the Episode from LibraryData.episodes by its id
    const episode = LibraryData.episodes.find((episode) => episode.id === id);

    // set the selected episode
    dispatchSelectedEpisodeState({
      type: "SET_EPISODE",
      episode: episode,
    });
    console.log("Selected Episode: ", episode);
  };

  useEffect(() => {
    if (LibraryData && !LibraryData.loading) {
      console.log("UserLibrary: ", LibraryData);

      // if the library is empty redirect to the discover page
      if (LibraryData.episodes && LibraryData.episodes.length === 0) {
        console.log("Library is empty:", LibraryData.episodes.length);
        navigate("/discover", { replace: true });
        toast.success("Your library is empty. Please add some podcasts.");
      }

      if (LibraryData.episodes && LibraryData.episodes.length > 0) {
        // If selectedEpisodeState.episode is already set, find the updated episode in LibraryData
        if (selectedEpisodeState.episode) {
          const updatedEpisode = LibraryData.episodes.find((episode) => episode.id === selectedEpisodeState.episode.id);
          if (updatedEpisode) {
            dispatchSelectedEpisodeState({
              type: "SET_EPISODE",
              episode: updatedEpisode,
            });
          }
        } else {
          // If selectedEpisodeState.episode is not set, initialize it with the first episode in LibraryData
          dispatchSelectedEpisodeState({
            type: "INIT",
            episode: LibraryData.episodes[0],
          });
        }
      } else {
        setShowModal(true);
      }
    }
  }, [LibraryData]);

  useEffect(() => {
    let timeoutId;

    const refreshEpisode = () => {
      if (selectedEpisodeState.episode_id && selectedEpisodeState.is_processing === true) {
        refreshLibraryEpisode(selectedEpisodeState.episode.feedId, selectedEpisodeState.episode_id);
        console.log("Episode is still processing");
        // Schedule the next refresh if is_processing is still true
        timeoutId = setTimeout(refreshEpisode, 5000); // Adjust the delay as needed
        console.log("Timeout ID: ", timeoutId);
      }
    };
    refreshEpisode();

    // Clear the timeout when the component unmounts, the dependencies change, or the episode is no longer selected
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        console.log("Clearing timeout: ", timeoutId);
      }
    };
  }, [selectedEpisodeState.episode_id, selectedEpisodeState.is_processing]);

  // Handle remove episode from library
  const RemoveEpisodeHandler = (feedId, episode_id) => {
    console.log("Remove episode: ", episode_id);
    // remove the episode from the library
    removeLibraryEpisode(feedId, episode_id);
    // if the removed episode is the selected episode, select the next episode
    if (selectedEpisodeState.episode_id === episode_id) {
      // check if the removed episode is the last one
      if (LibraryData.episodes.length === 0) {
        // DE_SELECT the episode
        dispatchSelectedEpisodeState({
          type: "DE_SELECT_EPISODE",
        });
        setShowModal(true);
      }
      // if the removed episode is the last one, select the previous episode
      else if (LibraryData.episodes.length === 1) {
        dispatchSelectedEpisodeState({
          type: "SET_EPISODE",
          episode: LibraryData.episodes[0],
        });
      }
    }
  };

  const HandleOnClose = () => {
    setShowModal(false);
    navigate("/discover", { replace: true });
  };

  if (LibraryData && LibraryData.loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FadeLoader color={"#fca311"} loading={LibraryData.loading} size={150} />
      </div>
    );
  }

  return (
    <>
      <SEO title="PodScribe.IO | Library" />

      <div className="flex-1 flex overflow-none">
        {/* Primary column */}
        {selectedEpisodeState.episode && (
          <InboxMessage
            episode={selectedEpisodeState.episode}
            data={selectedEpisodeEditorData}
            selectedEpisodeState={selectedEpisodeState}
            SelectNextEpisodeHandler={SelectNextEpisodeHandler}
            SelectPreviousEpisodeHandler={SelectPreviousEpisodeHandler}
            RemoveEpisodeHandler={RemoveEpisodeHandler}
            handleOpenSearchModal={handleOpenSearchModal}
          />
        )}

        {LibraryData.episodes && LibraryData.episodes.length > 0 && (
          <Inbox
            episodes={LibraryData.episodes}
            SelectEpisodeHandler={SelectEpisodeHandler}
            selectedEpisodeState={selectedEpisodeState}
          />
        )}
        {!LibraryData.episodes && <Inbox episodes={[]} />}
      </div>
      <Search
        openSearchModal={openSearchModal}
        setOpenSearchModal={setOpenSearchModal}
        SelectEpisodeHandler={SelectEpisodeHandler}
      />
      <Modal
        show={showModal}
        HandleOnClose={HandleOnClose}
        title="User Library is empty"
        body_text="Looks like your library is empty. Please add some podcasts."
        button_text="Discover podcasts"
        link="/discover"
      />
    </>
  );
}
