import React from "react";
import { Outlet } from "react-router-dom";
const Card = (props) => {
  return (
    <div
      className="flex py-4 px-4 space-x-2 space-y-2 border rounded-lg bg-gradient-to-r from-violet-100 via-purple-200 to-primary-300"
      key={props.id}
    >
      <div className="mr-4 flex-shrink-0 self-center">
        <img
          className="h-24 w-24 border border-neutral-300 bg-white text-neutral-300"
          src={props.imageUrl}
          alt={props.imageTitle}
        />
      </div>
      <div>
        <h4 className="text-lg font-bold">{props.title}</h4>
        <p className="mt-1">{props.description}</p>
        <div className="mt-3">{props.children}</div>
      </div>
    </div>
  );
};

export default Card;
