// render the podcasts from the Library
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useLibraryData from "../hooks/useLibraryData";
import useAuth from "../hooks/useAuth";
import Card from "./Card";
import Button from "../UI/Button";
import Breadcrumb from "../UI/Breadcrumb";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { remove_html_tags } from "../common/utils";

export default function Podcasts(props) {
  const { auth } = useAuth();
  const { LibraryData } = useLibraryData();
  let { FeedId } = useParams();
  const podcasts = LibraryData.podcasts.podcasts;
  // get the podcast array based on FeedId
  const podcast = podcasts.find(
    (podcast) => podcast.podcast.id === parseInt(FeedId),
  );
  const episodes = podcast.episodes;
  const pages = [
    { name: "Library", link: "/library/podcasts" },
    {
      name: podcast.podcast.title,
      link: `/library/podcasts/${FeedId}/episodes`,
    },
  ];
  return (
    <>
      <Breadcrumb pages={pages} />
      <ul role="list" className="divide-y divide-neutral-200">
        {episodes.map((episode) => {
          return (
            <li key={episode.id} className="py-4">
              <Card
                title={episode.title}
                description={remove_html_tags(episode.description)}
                imageUrl={episode.image}
                key={episode.id}
              >
                <Link to={`/library/${FeedId}/${episode.id}`}>
                  <Button>Open</Button>
                </Link>
              </Card>
            </li>
          );
        })}
      </ul>
    </>
  );
}
