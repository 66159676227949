// render the podcasts from the Library
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useLibraryData from "../hooks/useLibraryData";
import useAuth from "../hooks/useAuth";
import Card from "./Card";
import Button from "../UI/Button";
import Breadcrumb from "../UI/Breadcrumb";
import parse from "html-react-parser";
import SimpleCard from "./SimpleCard";
import { FadeLoader } from "react-spinners";
import toast from "react-hot-toast";
import { remove_html_tags } from "../common/utils";

export default function Podcasts(props) {
  const { auth } = useAuth();
  const { LibraryData, SetLibraryData } = useLibraryData();
  const pages = [{ name: "Library", link: "/library/podcasts" }];
  if (LibraryData.loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FadeLoader
          color={"#fca311"}
          loading={LibraryData.loading}
          size={150}
        />
      </div>
    );
  }
  if (LibraryData.error) {
    toast.error("Error! Something went wrong.");
  }

  return (
    <>
      {LibraryData.loading && <div>Loading...</div>}
      {!LibraryData.loading && LibraryData.podcasts && (
        <ul role="list" className="divide-y divide-neutral-200">
          {LibraryData.podcasts.map((data) => (
            <li key={data.podcast.id} className="py-6 px-6">
              <SimpleCard
                title={data.podcast.title}
                description={remove_html_tags(data.podcast.description)}
                imageUrl={data.podcast.image}
                key={data.podcast.id}
              >
                <Link to={`/library/podcasts/${data.podcast.id}/episodes`}>
                  <Button>Episodes</Button>
                </Link>
              </SimpleCard>
            </li>
          ))}
        </ul>
      )}
    </>
  );
}
