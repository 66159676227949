import React from "react";
import { Outlet } from "react-router-dom";
import { remove_html_tags } from "../common/utils";
const SimpleCard = (props) => {
  return (
    <div className="flex space-x-3">
      <img className="h-6 w-6 rounded-full" src={props.imageUrl} alt="" />
      <div className="flex-1 space-y-1">
        <div className="flex items-center justify-between">
          <h3 className="text-sm font-medium">{props.title}</h3>
          <p className="text-sm text-neutral-500"></p>
        </div>
        <p className="text-sm text-neutral-500">
          {remove_html_tags(props.description)}
        </p>
      </div>
    </div>
  );
};

export default SimpleCard;
