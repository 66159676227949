/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import {
  CreditCardIcon,
  OfficeBuildingIcon,
  UserIcon,
  UsersIcon,
  DocumentTextIcon,
  ChatIcon,
  PencilIcon,
  TemplateIcon,
} from "@heroicons/react/outline";
import { useState, useReducer, useEffect } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CardHeadingTabs(props) {
  const [tabs, setTabs] = useState([
    { name: "Summary", href: "#", icon: ChatIcon, current: true },
    { name: "MindMap", href: "#", icon: TemplateIcon, current: false },
    { name: "Transcript", href: "#", icon: DocumentTextIcon, current: false },
    { name: "Notes", href: "#", icon: PencilIcon, current: false },
  ]);
  const [selected, setSelected] = useState(tabs[0].name);
  useEffect(() => {
    props.onSelect(selected);
    // change current in tabs array according to selected
    const newTabs = tabs.map((tab) => {
      if (tab.name === selected) {
        tab.current = true;
      } else {
        tab.current = false;
      }
      return tab;
    });
    setTabs(newTabs);
  }, [selected]);

  return (
    <div>
      <div className="block">
        <div className="">
          <nav className="flex space-x-3 justify-center" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.name}
                href={tab.href}
                onClick={() => setSelected(tab.name)}
                className={classNames(
                  tab.current ? "text-primary-600" : " text-neutral-500 hover:text-neutral-700",
                  "group inline-flex items-center py-4 px-1 font-medium text-sm"
                )}
                aria-current={tab.current ? "page" : undefined}>
                <tab.icon
                  className={classNames(
                    tab.current ? "text-primary-500" : "text-neutral-400 group-hover:text-neutral-500",
                    "-ml-0.5 mr-2 h-5 w-5"
                  )}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
