import { useEffect, useState } from "react";
import Img from "../../UI/Img";
import { Tab } from "@headlessui/react";

import classNames from "classnames";

import { Container } from "./Container";
import backgroundImage from "../../assets/stacked-waves-haikei.svg";
import screenshot from "../../assets/screenshots/screenshot.png";
import screenshot1 from "../../assets/screenshots/screenshot1.png";
import screenshot2 from "../../assets/screenshots/screenshot2.png";
import screenshot6 from "../../assets/screenshots/screenshot6.png";
import screenshot8 from "../../assets/screenshots/screenshot8.png";

const features = [
  {
    title: "Recall & Organize",
    description:
      "PodScribe.IO transcribes podcast episodes, allowing users to skim through content, highlight key details, take notes, and revisit specific segments without re-listening to the entire episode.",
    image: screenshot1,
  },
  {
    title: "Key-Takeaways",
    description:
      "Going beyond mere transcription, our tool analyzes content to generate summaries, key takeaway notes, topics.  This feature empowers users to grasp the essence of an episode quickly, facilitating easier absorption and retention of information.",
    image: screenshot2,
  },
  {
    title: "Discover",
    description:
      "PodScribe.IO's discovery feature aids users in finding new podcasts and episodes. By adding episodes to a personalized inbox, users can request transcriptions, summaries and notes of episodes they wish to explore further, ensuring they stay informed.",
    image: screenshot6,
  },
  {
    title: "Shared Knowledge",
    description:
      "Users contribute to a shared knowledge base. Already processed episodes are available for all users, enabling them to access summaries and insights from a vast library of podcasts.",
    image: screenshot8,
  },
];

export function PrimaryFeatures() {
  let [tabOrientation, setTabOrientation] = useState("horizontal");

  useEffect(() => {
    let lgMediaQuery = window.matchMedia("(min-width: 1024px)");

    function onMediaQueryChange({ matches }) {
      setTabOrientation(matches ? "vertical" : "horizontal");
    }

    onMediaQueryChange(lgMediaQuery);
    lgMediaQuery.addEventListener("change", onMediaQueryChange);

    return () => {
      lgMediaQuery.removeEventListener("change", onMediaQueryChange);
    };
  }, []);

  return (
    <section
      id="features"
      aria-label="Features for running your books"
      className="relative overflow-hidden bg-primary-400 pb-28 pt-20 sm:py-32"
    >
      <Img
        className="absolute left-1/2 top-1/2 max-w-none translate-x-[-44%] translate-y-[-42%]"
        src={backgroundImage}
        alt=""
        width={2245}
        height={1636}
      />
      <Container className="relative">
        <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl md:text-5xl">
            Enhancing Your Podcast Experience with AI
          </h2>
          <p className="mt-6 text-lg tracking-tight text-primary-100">
            In today's fast-paced world, podcasts have become a key source of
            entertainment, education, and inspiration for many. However, the
            audio format, though convenient, poses challenges for note-taking
            and quick reference.
          </p>
        </div>
        <Tab.Group
          as="div"
          className="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0"
          vertical={tabOrientation === "vertical"}
        >
          {({ selectedIndex }) => (
            <>
              <div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
                <Tab.List className="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal">
                  {features.map((feature, featureIndex) => (
                    <div
                      key={feature.title}
                      className={classNames(
                        "group relative rounded-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-6",
                        selectedIndex === featureIndex
                          ? "bg-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10"
                          : "hover:bg-white/10 lg:hover:bg-white/5",
                      )}
                    >
                      <h3>
                        <Tab
                          className={classNames(
                            "font-display text-lg focus:outline-none",
                            selectedIndex === featureIndex
                              ? "text-primary-600 lg:text-white"
                              : "text-primary-100 hover:text-white lg:text-white",
                          )}
                        >
                          <span className="absolute inset-0 rounded-full lg:rounded-l-xl lg:rounded-r-none" />
                          {feature.title}
                        </Tab>
                      </h3>
                      <p
                        className={classNames(
                          "mt-2 hidden text-sm lg:block",
                          selectedIndex === featureIndex
                            ? "text-white"
                            : "text-primary-100 group-hover:text-white",
                        )}
                      >
                        {feature.description}
                      </p>
                    </div>
                  ))}
                </Tab.List>
              </div>
              <Tab.Panels className="lg:col-span-7">
                {features.map((feature) => (
                  <Tab.Panel key={feature.title} unmount={false}>
                    <div className="relative sm:px-6 lg:hidden">
                      <div className="absolute -inset-x-4 bottom-[-4.25rem] top-[-6.5rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl" />
                      <p className="relative mx-auto max-w-2xl text-base text-white sm:text-center">
                        {feature.description}
                      </p>
                    </div>
                    <div className="mt-10 w-[45rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-primary-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]">
                      <Img
                        className="w-full"
                        src={feature.image}
                        alt=""
                        sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                      />
                    </div>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </>
          )}
        </Tab.Group>
      </Container>
    </section>
  );
}
