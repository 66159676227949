import React from "react";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { BeatLoader } from "react-spinners";

export default function PricingSuccess() {
  const { auth, refreshUserData } = useAuth();
  const [subscriptionDeliveryCheck, setSubscriptionDeliveryCheck] =
    useState(true);
  const [subscriptionStatus, setSubscriptionStatus] = useState(
    auth.user.subscription_status,
  );

  useEffect(() => {
    setSubscriptionStatus(auth.user.subscription_status);
  }, [auth.user.subscription_status]);

  useEffect(() => {
    let timeoutId;

    const refreshState = () => {
      if (subscriptionStatus !== "active") {
        refreshUserData();
        console.log("Still processing...");
        timeoutId = setTimeout(refreshState, 1000); // Adjust the delay as needed
        console.log("Timeout ID: ", timeoutId);
      }
      if (subscriptionStatus === "active") {
        console.log("Subscription active");
        clearTimeout(timeoutId);
        setSubscriptionDeliveryCheck(false);
      }
    };
    refreshState();

    // Clear the timeout when the component unmounts, the dependencies change, or the episode is no longer selected
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        console.log("Clearing timeout: ", timeoutId);
      }
    };
  }, [subscriptionStatus]);

  return (
    <>
      <div className=" bg-white px-4 py-5 border-b border-neutral-200 sm:px-6 m-3">
        {/* Page Header */}
        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h3 className="text-lg leading-6 font-medium text-primary-600">
              Subscription
            </h3>
            <p className="text-sm leading-6 text-neutral-500">
              Success! Your subscription has been processed.
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <CheckCircleIcon className="h-24 w-24 text-primary-600 mx-auto" />
            <p className="mt-2 text-4xl font-bold tracking-tight text-neutral-900 sm:text-5xl">
              We are thrilled to have you on board!
            </p>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-neutral-600">
            Your subscription has been successfully processed. Please allow a
            few minutes for the changes to take effect, to access all our
            features.
          </p>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-neutral-600">
            If you have any questions, please contact us at{" "}
            <a href="mailto:info@podscribe.io" className="text-primary-600">
              info@podscribe.io
            </a>
            .
          </p>
        </div>
        {/* Loading spinner */}
        {subscriptionDeliveryCheck ? (
          <div className="flex justify-center items-center">
            <BeatLoader
              color={"#fca311"}
              loading={subscriptionDeliveryCheck}
              size={15}
            />
          </div>
        ) : (
          <div className="flex justify-center items-center">
            <p className="text-center text-lg text-support-green-600">
              Subscription configured successfully!
            </p>
          </div>
        )}
      </div>
    </>
  );
}
