import react, { useEffect, useState, useRef } from "react";
import { Fragment } from "react";
import CardHeadingChildButtons from "../UI/CardHeadingChildButtons";
import SEO from "./SEO";
import {
  SearchIcon,
  XIcon,
  PlusIcon,
  CheckIcon,
  LightningBoltIcon as LightningBoltIconOutline,
} from "@heroicons/react/outline";
import { Link, useParams } from "react-router-dom";
import Button from "../UI/Button";
import PodcastCard from "../UI/PodcastCard";
import { axiosPrivate } from "../common/axiosPrivate";
import { BarLoader } from "react-spinners";
import { Helmet } from "react-helmet";
import { useReducer } from "react";
import PodcastProfileHeadingCard from "../UI/PodcastProfileHeadingCard";
import PodcastEpisodeCard from "../UI/PodcastEpisodeCard";
import SearchInput from "../UI/SearchComponent";
import { LightningBoltIcon } from "@heroicons/react/solid";
import { Switch } from "@headlessui/react";
import useLibraryData from "../hooks/useLibraryData";
import { useDebounceValue } from "usehooks-ts";
import ActionModalPleaseSubscribeToUse from "../UI/ActionModalPleaseSubscribeToUse";
import useAuth from "../hooks/useAuth";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

//reducer

const FETCH_DATA = "FETCH_DATA";
const FILTER_FEEDS = "FILTER_FEEDS";
const TRANSCRIBED_FILTER = "TRANSCRIBED_FILTER";
const FAVORITE = "FAVORITE";
const SHOW_MORE = "SHOW_MORE";
const ADD_EPISODE = "ADD_EPISODE";
const REMOVE_EPISODE = "REMOVE_EPISODE";

const initialState = {
  podcast: [],
  episodes: [],
  processedEpisodes: [],
  filteredEpisodes: [],
  displayCount: 20, // New state for tracking display count
};

const episodesReducer = (state, action) => {
  switch (action.type) {
    case FETCH_DATA:
      return {
        ...state,
        podcast: action.payload.podcast,
        allEpisodes: action.payload.episodes,
        episodes: action.payload.episodes,
        processedEpisodes: action.payload.episodes.filter((episode) => episode.is_transcribed),
      };

    case FILTER_FEEDS:
      // if both conditions are true
      if (action.payload) {
        return {
          ...state,
          filteredEpisodes: state.episodes.filter((episode) =>
            episode.title.toLowerCase().includes(action.payload.toLowerCase())
          ),
        };
      }
      return {
        ...state,
        filteredEpisodes: Array.isArray(state.episodes) ? state.episodes.slice(0, state.displayCount) : [],
      };
    case TRANSCRIBED_FILTER:
      if (action.payload) {
        return {
          ...state,
          episodes: state.processedEpisodes,
        };
      } else {
        return {
          ...state,
          episodes: state.allEpisodes,
        };
      }

    case FAVORITE:
      const feedId = action.payload.feedId;
      const isFavorite = action.payload.isFavorite;
      return {
        ...state,
        podcast: {
          ...state.podcast,
          is_favorite: isFavorite,
        },
      };
    case SHOW_MORE:
      return {
        ...state,
        displayCount: state.displayCount + 5,
      };
    case ADD_EPISODE:
      const episodeId = action.payload.episodeId;
      const isAdded = action.payload.isAdded;
      const episodes = state.episodes.map((episode) => {
        if (episode.id === episodeId) {
          return { ...episode, is_added_to_library: isAdded };
        }
        return episode;
      });
      return {
        ...state,
        episodes,
      };
    case REMOVE_EPISODE:
      const episodeIdRemove = action.payload.episodeId;
      const isAddedRemove = action.payload.isAdded;
      const episodesRemove = state.episodes.map((episode) => {
        if (episode.id === episodeIdRemove) {
          return { ...episode, is_added_to_library: isAddedRemove };
        }
        return episode;
      });
      return {
        ...state,
        episodes: episodesRemove,
      };

    default:
      return state;
  }
};

export default function PodcastProfile(props) {
  const { feedId } = useParams();
  const { auth, refreshUserData } = useAuth();
  const { transcribedOnly } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [state, dispatch] = useReducer(episodesReducer, initialState);
  const [filterTerm, setFilterTerm] = useState("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useDebounceValue("", 300);
  const [filterTranscribed, setFilterTranscribed] = useState(false);
  const { LibraryData, setLibraryData, refreshLibraryEpisode, removeLibraryEpisode, addLibraryEpisode } =
    useLibraryData();
  const [showModal, setShowModal] = useState(false);

  const handleAddRemove = (feedId, episodeId, isAdded) => {
    console.log("Add/Remove episode: ", feedId, episodeId, isAdded);
    // invok an async API call to add or remove episode
    const AddEpisodeToUserLibrary = async (feedId, episodeId) => {
      try {
        addLibraryEpisode(feedId, episodeId);
        dispatch({ type: ADD_EPISODE, payload: { episodeId, isAdded } });
        dispatch({ type: FILTER_FEEDS, payload: filterTerm });
      } catch (error) {
        console.error("Error adding episode to library: ", error);
      }
    };
    const RemoveEpisodeFromUserLibrary = async (feedId, episodeId) => {
      try {
        removeLibraryEpisode(feedId, episodeId);
        dispatch({ type: REMOVE_EPISODE, payload: { episodeId, isAdded } });
        dispatch({ type: FILTER_FEEDS, payload: filterTerm });
      } catch (error) {
        console.error("Error removing episode from library: ", error);
      }
    };
    // if we reached the max number of episodes in the library, show a modal
    if (auth.user.subscription_status == null && LibraryData.episodes.length >= 3 && isAdded) {
      console.log("Show modal");
      setShowModal(true);
      return;
    }
    if (isAdded) {
      AddEpisodeToUserLibrary(feedId, episodeId);
    } else {
      RemoveEpisodeFromUserLibrary(feedId, episodeId);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axiosPrivate.get(`/podcast/${feedId}/episodes`);
        console.log("PodcastProfile fetched data: ", response.data);
        dispatch({ type: FETCH_DATA, payload: response.data });
        dispatch({ type: FILTER_FEEDS });
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    console.log("Filtered feeds: ", state.filteredFeeds);
    console.log("Display count: ", state.displayCount);
    console.log("All feeds: ", state.feeds);
  }, [state.episodes, state.filteredEpisodes, state.displayCount]);

  const handleShowMore = (state, dispatch) => {
    dispatch({ type: SHOW_MORE });
    dispatch({ type: FILTER_FEEDS });
  };
  const handleFavoriteOnClick = async (feedId, isFavorite) => {
    try {
      await axiosPrivate.post(`/podcast/favorites/${feedId}`, {
        is_favorite: isFavorite,
      });
    } catch (error) {
      console.error("Error updating favorite status: ", error);
    }
    dispatch({ type: "FAVORITE", payload: { feedId, isFavorite } });
  };

  useEffect(() => {
    dispatch({ type: TRANSCRIBED_FILTER, payload: filterTranscribed });
    dispatch({ type: FILTER_FEEDS });
  }, [filterTranscribed]);

  const handleSearch = (e) => {
    setDebouncedSearchValue(e.target.value);
    setFilterTerm(e.target.value);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    dispatch({ type: FILTER_FEEDS, payload: debouncedSearchValue });
  }, [debouncedSearchValue]);

  return (
    <>
      <SEO title="PodScribe.IO | Podcast Profile" />
      <div className="flex">
        <section
          aria-labelledby="message-heading"
          className="min-w-0 flex-1 lg:h-screen flex flex-col overflow-y-auto lg:order-last">
          {/* Main Content. Note the lg:h-screen is important because of the Navigation menu */}
          <BarLoader width={"100%"} color={"#fca311"} loading={isLoading} size={150} />
          <CardHeadingChildButtons title="Podcast Profile" subHeading="View all episodes of a podcast">
            <div className="flex flex-row space-x-3">
              <Switch.Group as="div" className="flex items-center mt-3">
                <Switch
                  checked={filterTranscribed}
                  onChange={setFilterTranscribed}
                  className={classNames(
                    filterTranscribed ? "bg-primary-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2"
                  )}>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      filterTranscribed ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
                <Switch.Label as="span" className="ml-3 text-sm">
                  <span className="font-medium text-gray-900">Transcribed</span>{" "}
                </Switch.Label>
              </Switch.Group>
              <SearchInput
                value={filterTerm}
                onChange={(e) => {
                  handleSearch(e);
                }}
                className="rounded-r-md"
              />
            </div>
          </CardHeadingChildButtons>
          <div className="flex flex-col flex-1 lg:overflow-y-auto items-center">
            <div className="h-screen h-screen-ios w-screen md:w-8/12 lg:w-9/12 px-2 py-2">
              {/* Search results go here if there are*/}

              {!isLoading && state.episodes.length === 0 && (
                <div className="text-center mt-4">
                  <h3 className="text-lg text-neutral-400">
                    Podcast not found. <Link to="/discover">Discover</Link> more podcasts.
                  </h3>
                </div>
              )}
              <div className="mt-4 mx-3">
                {!isLoading && state.podcast && (
                  <PodcastProfileHeadingCard feed={state.podcast} handleFavorite={handleFavoriteOnClick} />
                )}
              </div>
              <div className="mt-4 mx-3">
                <h2 className="text-xl font-bold text-left text-primary-600">Episodes</h2>
                {!isLoading &&
                  state.filteredEpisodes.map((episode, index) => (
                    <PodcastEpisodeCard key={index} episode={episode} handleAddRemove={handleAddRemove} />
                  ))}
              </div>
              <div className="mt-4 mx-3 pb-12">
                {!filterTerm && !isLoading && state.displayCount < state.episodes.length && (
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center">
                      <Button type="button" onClick={() => handleShowMore(state, dispatch)} className="py-1 gap-x-1">
                        <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        Load More
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <ActionModalPleaseSubscribeToUse
          show={showModal}
          HandleOnClose={handleCloseModal}
          CloseButtonText="Cancel"
          title="Add Episode to Library"
          BodyText="You have reached the maximum (3) number of episodes in your library. Subscribe to add more episodes."
          button_text="Back to login"
        />
      </div>
    </>
  );
}
