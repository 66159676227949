import { React, useState, useEffect } from "react";
import {
  HeartIcon,
  ChevronRightIcon,
  ExternalLinkIcon,
  FolderAddIcon,
  FolderRemoveIcon,
  LightningBoltIcon,
  DownloadIcon,
} from "@heroicons/react/outline";
import { PlayIcon, PauseIcon, LightBulbIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import Img from "./Img";

function Player({ url }) {
  const [playing, setPlaying] = useState(false);

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  return (
    <div>
      <button onClick={handlePlayPause}>
        {playing ? (
          <PauseIcon className="h-6 w-6 text-primary-500" aria-hidden="true" />
        ) : (
          <PlayIcon className="h-6 w-6 text-primary-500" aria-hidden="true" />
        )}
      </button>
      <ReactPlayer
        url={url}
        playing={playing}
        width="0"
        height="0"
        config={{
          file: {
            forceAudio: true,
            preload: false,
          },
        }}
      />
    </div>
  );
}

export default function PodcastEpisodeCard(props) {
  const { episode } = props;

  const handleAddRemove = (feedId, episodeId) => {
    console.log("Episode: ", episode);
    props.handleAddRemove(feedId, episodeId, !episode.is_added_to_library);
  };

  useEffect(() => {
    console.log("Episode in the PodcastEpisodeCard: ", episode);
  }, [episode]);

  return (
    <div className="relative sm:flex border border-neutral-200 p-3 lg:p-6 rounded-lg mt-6 mb-6">
      <div className="flex flex-col">
        <div className="flex flex-col sm:flex-row">
          <div className="mb-4 flex-shrink-0 sm:mb-0 sm:mr-4">
            <Img
              src={episode.feedImage}
              alt={episode.title}
              className="h-16 w-16 border border-gray-300 bg-white text-gray-300"
            />
          </div>
          <div>
            <Link to={episode.link} target="_blank" className="group">
              <div className="flex-1 sm:flex sm:flex-row">
                <h4 className="flex text-lg font-bold text-primary-600 group-hover:text-secondary-600">
                  {episode.title.length > 50 ? episode.title.substring(0, 50) + "..." : episode.title}
                </h4>{" "}
                <ExternalLinkIcon
                  className="ml-1 h-5 w-5 text-primary-600 group-hover:text-secondary-600"
                  aria-hidden="true"
                />
              </div>
            </Link>
            <div className="flex flex-row space-x-3">
              <h4 className="text-sm font-medium mt-1">
                Posted on{" "}
                <span className=" text-neutral-500">
                  {new Date(episode.datePublished * 1000).toLocaleDateString("en-US")}
                </span>
              </h4>

              <Link to={episode.enclosureUrl} target="_blank">
                <DownloadIcon className="h-5 w-5 text-primary-600" aria-hidden="true" />
              </Link>
              <Player url={episode.enclosureUrl} />
              {episode.is_transcribed ? (
                <span className="inline-flex items-center rounded-lg px-2 py-1 text-xs font-medium bg-white text-secondary-600 ring-1 ring-inset ring-primary-400">
                  <LightningBoltIcon className="h-4 w-4 mr-1 fill-secondary-600" aria-hidden="true" />
                  Podscribed
                </span>
              ) : null}
            </div>
            <div className="absolute top-3 right-3">
              <button onClick={() => handleAddRemove(episode.feedId, episode.id)}>
                {episode.is_added_to_library ? (
                  <FolderRemoveIcon className="h-8 w-8 text-red-600" aria-hidden="true" />
                ) : (
                  <FolderAddIcon className="h-8 w-8 text-primary-500" aria-hidden="true" />
                )}
              </button>
            </div>
            <div className="flex flex-wrap mt-2 space-x-1"></div>
          </div>
        </div>
        <div className="">
          <p className="mt-1 text-justify">
            {episode.description.length > 200 ? episode.description.substring(0, 200) + "..." : episode.description}
          </p>
        </div>
      </div>
    </div>
  );
}
