import { useId } from "react";
import Img from "../../UI/Img";
import { Tab } from "@headlessui/react";

import classNames from "classnames";

import { Container } from "./Container";
import screenshot9 from "../../assets/screenshots/screenshot9.png"; // search
import screenshot7 from "../../assets/screenshots/screenshot7.png"; // favorites
import screenshot11 from "../../assets/screenshots/screenshot11.png"; // notes
import screenshot17 from "../../assets/screenshots/screenshot17.png"; // mobile
import { HeartIcon, PencilAltIcon, SearchIcon, DeviceMobileIcon } from "@heroicons/react/solid";

const features = [
  {
    name: "Search",
    summary: "Access and Search Knowledge Data.",
    description:
      "Our user-friendly inbox acts as a central repository for all knowledge data extracted from podcasts. Users can search transcripts, summaries, and key insights, simplifying the process of finding information on specific topics or revisiting favorite episodes.",
    image: screenshot9,
    icon: function ReportingIcon() {
      let id = useId();
      return (
        <>
          <SearchIcon className="h-9 w-9 fill-white" />
        </>
      );
    },
  },
  {
    name: "Favorites",
    summary: "Save and Organize Your Favorite Podcasts.",
    description:
      'Users can mark podcasts as "favorites" to create a personalized library of podcasts they wish to revisit. This feature allows users to curate a collection of episodes that resonate with them, making it easier to find and listen to their favorite content.',
    image: screenshot7,
    icon: function InventoryIcon() {
      return (
        <>
          <HeartIcon className="h-9 w-9 fill-white" />
        </>
      );
    },
  },
  {
    name: "Custom Notes",
    summary: "Capture and Save Your Thoughts.",
    description:
      "This feature facilitates a deeper connection with the content and encourages users to capture their thoughts, ideas, and reflections on episodes. Users can add notes to episodes, highlight key details, and jot down their insights for future reference.",
    image: screenshot11,
    icon: function ContactsIcon() {
      return (
        <>
          <PencilAltIcon className="h-9 w-9 fill-white" />
        </>
      );
    },
  },
];

function Feature({ feature, isActive, className, ...props }) {
  return (
    <div className={classNames(className, !isActive && "opacity-75 hover:opacity-100")} {...props}>
      <div className={classNames("w-9 rounded-lg", isActive ? "bg-primary-600" : "bg-slate-500")}>
        <div aria-hidden="true" className="h-9 w-9" fill="None">
          <feature.icon />
        </div>
      </div>
      <h3 className={classNames("mt-6 text-sm font-medium", isActive ? "text-primary-600" : "text-slate-600")}>
        {feature.name}
      </h3>
      <p className="mt-2 font-display text-xl text-slate-900">{feature.summary}</p>
      <p className="mt-4 text-sm text-slate-600">{feature.description}</p>
    </div>
  );
}

function FeaturesMobile() {
  return (
    <div className="-mx-4 mt-20 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden">
      {features.map((feature) => (
        <div key={feature.name}>
          <Feature feature={feature} className="mx-auto max-w-2xl" isActive />
          <div className="relative mt-10 pb-10">
            <div className="absolute -inset-x-4 bottom-0 top-8 bg-slate-200 sm:-inset-x-6" />
            <div className="relative mx-auto w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
              <img className="w-full" src={feature.image} alt="" sizes="52.75rem" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

function FeaturesDesktop() {
  return (
    <Tab.Group as="div" className="hidden lg:mt-20 lg:block">
      {({ selectedIndex }) => (
        <>
          <Tab.List className="grid grid-cols-3 gap-x-8 gap-y-8">
            {features.map((feature, featureIndex) => (
              <Feature
                key={feature.name}
                feature={{
                  ...feature,
                  name: (
                    <Tab className="focus:outline-none">
                      <span className="absolute inset-0" />
                      {feature.name}
                    </Tab>
                  ),
                }}
                isActive={featureIndex === selectedIndex}
                className="relative"
              />
            ))}
          </Tab.List>
          <Tab.Panels className="relative mt-20 overflow-hidden rounded-4xl bg-slate-200 px-14 py-16 xl:px-16">
            <div className="-mx-5 flex">
              {features.map((feature, featureIndex) => (
                <Tab.Panel
                  static
                  key={feature.name}
                  className={classNames(
                    "px-5 transition duration-500 ease-in-out focus:outline-none",
                    featureIndex !== selectedIndex && "opacity-60"
                  )}
                  style={{ transform: `translateX(-${selectedIndex * 100}%)` }}
                  aria-hidden={featureIndex !== selectedIndex}>
                  <div className="w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
                    <img className="w-full" src={feature.image} alt="" sizes="52.75rem" />
                  </div>
                </Tab.Panel>
              ))}
            </div>
            <div className="pointer-events-none absolute inset-0 rounded-4xl ring-1 ring-inset ring-slate-900/10" />
          </Tab.Panels>
        </>
      )}
    </Tab.Group>
  );
}

export function SecondaryFeatures() {
  return (
    <section
      id="secondary-features"
      aria-label="Features for simplifying everyday business tasks"
      className="pb-14 pt-20 sm:pb-20 sm:pt-32 lg:pb-32">
      <Container>
        <div className="mx-auto max-w-2xl md:text-center">
          <h2 className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
            Amplify Your Podcast Journey
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            PodScribe.IO revolutionizing how users engage with podcasts using cutting-edge ML & AI technology.
          </p>
        </div>
        <FeaturesMobile />
        <FeaturesDesktop />
      </Container>
    </section>
  );
}
