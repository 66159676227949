import { ChevronDownIcon, SearchIcon, SortAscendingIcon } from "@heroicons/react/solid";
import { useState, useEffect, useRef } from "react";
import CardHeadingTabs from "./CardHeadingTabs";
import { XCircleIcon } from "@heroicons/react/outline";
import SearchInput from "./SearchComponent";

export default function CardHeadingSearch(props) {
  const [searchTerm, setSearchTerm] = useState("");

  const onSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    const timeOutId = setTimeout(() => props.onSearch(searchTerm), 500);
    return () => clearTimeout(timeOutId);
  }, [searchTerm]);

  return (
    <div className="flex flex-col items-center pb-5 border-b border-neutral-200 sm:flex sm:flex-row sm:items-center sm:justify-between mb-3">
      <CardHeadingTabs onSelect={props.onSelect} />
      {props.showSearchBar ? (
        <div className="flex flex-row items-center justify-between">
          <SearchInput value={searchTerm} onChange={onSearch} placeholder="Search Transcript..." className="w-52" />
        </div>
      ) : null}
    </div>
  );
}
