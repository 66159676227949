import { React, useState } from "react";
import { HeartIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { HeartIcon as HeartIconOutlined, ExternalLinkIcon } from "@heroicons/react/outline";
import Img from "./Img";

export default function PodcastCard(props) {
  const { feed } = props;

  const handleFavorite = (feedId) => {
    props.handleFavorite(feedId, !feed.is_favorite);
  };

  return (
    <div className="relative sm:flex border border-neutral-200 p-3 lg:p-6 rounded-lg mt-6 mb-6">
      <div className="flex flex-col">
        <div className="flex flex-col sm:flex-row">
          <div className="mb-4 flex-shrink-0 sm:mb-0 sm:mr-4">
            <Img src={feed.image} alt={feed.title} className="h-24 w-24 sm:h-32 sm:w-32 rounded-lg" />
          </div>
          <div>
            <Link to={`/podcasts/${feed.id}/episodes`} className="group">
              <h4 className="flex text-lg font-bold text-primary-600 group-hover:text-secondary-600">
                {feed.title}
                <ChevronRightIcon
                  className="h-8 w-8 text-primary-600 group-hover:text-secondary-600"
                  aria-hidden="true"
                />
              </h4>
            </Link>
            <h4 className="text-sm font-medium">
              by <span className=" text-secondary-500">{feed.author}</span>
            </h4>
            <div className="absolute top-3 right-3">
              <button onClick={() => handleFavorite(feed.id)}>
                {feed.is_favorite ? (
                  <HeartIcon className="h-6 w-6 text-red-500" aria-hidden="true" />
                ) : (
                  <HeartIconOutlined className="h-6 w-6 text-red-500" aria-hidden="true" />
                )}
              </button>
            </div>
            <div className="flex flex-wrap mt-2 space-x-1">
              {feed.categories &&
                Object.values(feed.categories).map((category, i) => (
                  <span
                    key={i}
                    className="inline-flex items-center rounded-md bg-primary-50 px-2 py-1 text-xs font-medium text-primary-700 ring-1 ring-inset ring-primary-700/10">
                    {category}
                  </span>
                ))}
            </div>
          </div>
        </div>
        <div className="">
          <p className="mt-3 text-justify">
            {feed.description.length > 200 ? feed.description.substring(0, 200) + "..." : feed.description}
          </p>
        </div>
      </div>
    </div>
  );
}
