import Img from "../../UI/Img";

import { Container } from "./Container";
import backgroundImage from "../../assets/background-faqs.jpg";

const faqs = [
  [
    {
      question: 'What is a "Podscribe"?',
      answer:
        'A "Podscribe" refers to our service of transcripting the podcast episode you choose, providing a written summary, quick takeaways, and key points per topic detected in the episode.',
    },
    {
      question: 'How can I request a "Podscribe"?',
      answer:
        'You can request a "Podscribe" directly from your Inbox. Simply navigate to the episode and click on "Podscribe" button. Please allow a few minutes for it to complete.',
    },
    {
      question: 'Is there a limit to how many "Podscribes" I can request?',
      answer: 'Yes, the number of "Podscribes" you can request depends on your subscription level.',
    },
    {
      question: "What is fair use policy?",
      answer:
        "In the premium plan, you can request a transcription of any podcast episode. However, we have a fair use policy in place to ensure that the service is not abused. We reserve the right to limit the number of transcriptions you can request in a given period. This limit is currently set at 50 transcriptions per month. If you need more, please contact us.",
    },
  ],
  [
    {
      question: "Can I discover new podcasts and episodes via the app?",
      answer:
        "Absolutely! Our app features a 'Discover' section where you can explore new podcasts and episodes. If you find ones you love, you can add them to your inbox and order a 'Podscribe' if you wish.",
    },
    {
      question: "Can I chat with the Podcast transcript using generative AI functionality?",
      answer: "This is a feature we are currently working on and will be available soon.",
    },
    {
      question: "Do I have access to episode transcripts, summaries, and key takeaways other users have created?",
      answer:
        "Yes, absolutely. As a user with any of our paid packages, you have access to episode transcripts, summaries, and key takeaways generated by other users. This is part of the subscription package and provides a great way to discover new knowledge and insights from a variety of podcasts. Enjoy your unlimited exploration of shared knowledge across our vast podcast library.",
    },
  ],
  [
    {
      question: "How accurate are the transcriptions, summaries, and key takeaways created by your service?",
      answer:
        "We strive to ensure our transcriptions are as accurate as possible. However, keep in mind that the accuracy can slightly vary depending upon the audio quality and clarity of speech in the podcast.",
    },
    {
      question: "I have forgotten my password. How can I reset it?",
      answer: "Please send us an email and we will help you reset your password.",
    },
    {
      question: "How to request best-effort podcast transcription?",
      answer:
        "If you think the podcast you want to podscribe would be beneficial to the community, you can like the episode.  We can only spend a pool of credits on this, and will consider the most liked episodes.",
    },
  ],
];

export function Faqs() {
  return (
    <section id="faq" aria-labelledby="faq-title" className="relative overflow-hidden bg-slate-50 py-20 sm:py-32">
      <Img
        className="absolute left-1/2 top-0 max-w-none -translate-y-1/4 translate-x-[-30%]"
        src={backgroundImage}
        alt=""
        width={1558}
        height={946}
      />
      <Container className="relative">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 id="faq-title" className="font-display text-3xl tracking-tight text-primary-900 sm:text-4xl">
            Frequently asked questions
          </h2>
          <p className="mt-4 text-lg tracking-tight text-primary-700">
            If you can’t find what you’re looking for, email our support team and if you’re lucky someone will get back
            to you.
          </p>
        </div>
        <ul role="list" className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3">
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="flex flex-col gap-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="font-display text-lg leading-7 text-primary-900">{faq.question}</h3>
                    <p className="mt-4 text-sm text-primary-700">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
}
