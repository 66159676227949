import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Register from "./components/Register";
import { VerifyEmail } from "./components/Register";
import NotFound from "./components/NotFound";
import RequireAuthentication from "./components/RequireAuthentication";
import Discover from "./components/Discover";
import Library from "./components/Library";
import Login from "./components/Login";
import Podcasts from "./components/Podcasts";
import Episodes from "./components/Episodes";
import Logout from "./components/Logout";
import Refresh from "./components/Refresh";
import Repository from "./components/Repository";
import Profile from "./components/User/Profile";
import Subscription from "./components/User/Subscription";
import Terms from "./components/Landing/Terms";
import Home from "./components/index";
import Processing from "./components/Processing";
import Privacy from "./components/Landing/Privacy";
import Cookie from "./components/Landing/Cookie";
import Refund from "./components/Landing/Refund";
import RequireSubscription from "./components/RequireSubscription";
import Favorites from "./components/Favorites";
import DiscoverSearchResults from "./components/DiscoverSearchResults";
import PodcastProfile from "./components/PodcastProfile";
import PricingSuccess from "./components/User/PricingSuccess";
import CancelSubscription from "./components/User/CancelSubscription";
import PricingUpdateSuccess from "./components/User/PricingUpdateSuccess";
import RegistrationSuccess from "./components/User/RegistrationSuccess";
import ContentLayout from "./components/Landing/content/ContentLayout";
import PodcastGrid from "./components/Landing/content/ContentPodcasts";
import PodcastEpisodes from "./components/Landing/content/ContentPodcastEpisodes";
import ContentEpisodeNotes from "./components/Landing/content/ContentEpisodeNotes";
// import LogRocket from "logrocket";

// LogRocket.init("f5yhbt/podscribeio");
// This is an example script - don't forget to change it!

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy-policy" element={<Privacy />} />
      <Route path="/cookie-policy" element={<Cookie />} />
      <Route path="/refund-policy" element={<Refund />} />
      <Route path="login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="register" element={<Register />} />
      <Route path="/refresh" element={<Refresh />} />
      <Route path="/verifyemail/:ActivationCode" element={<VerifyEmail />} />
      <Route path="/registration-success" element={<RegistrationSuccess />} />
      <Route path="/content" element={<ContentLayout />}>
        <Route path="/content/podcasts" element={<PodcastGrid />} />
        <Route
          path="/content/podcasts/:podcastId/episodes"
          element={<PodcastEpisodes />}
        />
        <Route
          path="/content/podcasts/:podcastId/episode/:episodeId"
          element={<ContentEpisodeNotes />}
        />
      </Route>
      <Route element={<Layout />}>
        <Route element={<RequireAuthentication />}>
          <Route path="/discover" element={<Discover />} />
          <Route
            path="/discover/results/:searchTerm"
            element={<DiscoverSearchResults />}
          />
          <Route
            path="/podcasts/:feedId/episodes"
            element={<PodcastProfile />}
          />
          <Route path="/favorites" element={<Favorites />} />
          <Route path="/repository" element={<Repository />} />
          <Route path="/library" element={<Library />}>
            <Route path="/library/podcasts" element={<Podcasts />} />
            <Route
              path="/library/podcasts/:FeedId/episodes"
              element={<Episodes />}
            />
          </Route>
          <Route path="/profile" element={<Profile />} />
          <Route path="/subscription/" element={<Subscription />} />
          <Route path="/subscription/success" element={<PricingSuccess />} />
          <Route
            path="/subscription/update/success"
            element={<PricingUpdateSuccess />}
          />
          <Route path="/subscription/cancel" element={<CancelSubscription />} />
        </Route>
      </Route>
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
