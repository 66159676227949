export default function CardHeadingChildButtons(props) {
  return (
    <div className=" bg-white px-4 py-5 border-b border-neutral-200 sm:px-6 m-3">
      <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
        <div className="flex flex-col">
          <div className="ml-4 mt-2">
            <h3 className="text-lg leading-6 font-medium text-primary-600">
              {props.title}
            </h3>
          </div>
          <div className="ml-4 mt-2 flex-shrink-0 text-neutral-800 text-sm">
            <h4 className="text-sm leading-6 font-medium text-neutral-800">
              {props.subHeading}
            </h4>
          </div>
        </div>
        <div className="ml-4 mt-2 flex-shrink-0">{props.children}</div>
      </div>
    </div>
  );
}
