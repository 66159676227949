import react, { useEffect, useState, useRef } from "react";
import { Fragment } from "react";
import CardHeadingChildButtons from "../UI/CardHeadingChildButtons";
import { SearchIcon, XIcon } from "@heroicons/react/outline";
import { Link, useNavigate } from "react-router-dom";
import SEO from "./SEO";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// Module scoped variables

export default function Discover(props) {
  const searchInputRef = useRef(null);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchOnClick = () => {
    console.log("Search term: ", searchInputRef.current.value);
    // redirect to search results page
    navigate(`/discover/results/${searchInputRef.current.value}`);
  };

  const hanleInputOnChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearchOnClick = () => {
    searchInputRef.current.value = "";
    setSearchTerm("");
  };

  return (
    <>
      <SEO title="PodScribe.IO | Discover" />
      <div className="flex">
        <section
          aria-labelledby="message-heading"
          className="min-w-0 flex-1 lg:h-screen flex flex-col overflow-y-auto lg:order-last h-screen-ios h-screen">
          {/* Main Content. Note the lg:h-screen is important because of the Navigation menu */}
          <CardHeadingChildButtons title="Discover" subHeading="Discover new podcasts and episodes" />
          <div className="flex flex-col flex-1 lg:overflow-y-auto items-center mt-24 lg:mt-36">
            <div className="text-center">
              <div className="flex flex-row">
                <div>
                  <div className="relative mt-2 rounded-md shadow-sm">
                    <input
                      type="text"
                      ref={searchInputRef}
                      name="search"
                      id="search"
                      title="Press Enter to search"
                      onChange={hanleInputOnChange}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          handleSearchOnClick(event);
                        }
                      }}
                      className="block w-full rounded-l-lg border-0 py-3 pl-8 lg:pl-8 lg:pr-8 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Search podcasts"
                    />
                    <div className="absolute inset-y-0 right-1 flex items-center pl-3">
                      {searchTerm.length > 0 && (
                        <button onClick={handleClearSearchOnClick} className="">
                          <span className="sr-only">Clear search</span>
                          <XIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <button
                  onClick={handleSearchOnClick}
                  className="inline-flex mt-2 py-3 px-3 items-center border border-transparent text-sm font-medium rounded-r-md shadow-sm text-white bg-primary-400 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                  <SearchIcon className="h-5 w-5 text-white" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
